<template>
  <div class="wrap">
    <div class="nav">
      <div class="navList">
        <div @click="jump('1')">
          <span>首页</span>
          <span>Home</span>
        </div>
        <div @click="jump('2')">
          <span>服务案例</span>
          <span>Case</span>
        </div>
        <div @click="jump('3')">
          <span>新闻资讯</span>
          <span>News</span>
        </div>
        <div @click="jump('4')" class="cur">
          <span>业务伙伴</span>
          <span>Cooperation</span>
        </div>
        <div @click="jump('5')">
          <span>关于我们</span>
          <span>About</span>
        </div>
      </div>
    </div>
    <div class="title">
      <img src="../../public/imgs/partner/COOPERATIVEPARTNER.png" alt="" />
    </div>
    <div class="main">
      <img src="../../public/imgs/partner/11.png" alt="" />
      <img src="../../public/imgs/partner/12.png" alt="" />
      <img src="../../public/imgs/partner/13.png" alt="" />
      <img src="../../public/imgs/partner/14.png" alt="" />
      <img src="../../public/imgs/partner/15.png" alt="" />
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../components/Footer";
export default {
  name: "index",
  components: {
    Footer,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    jump(index) {
      if (index == 1) {
        this.$router.push({
          path: "/",
        });
      }
      if (index == 2) {
        this.$router.push({
          path: "/case",
        });
      }
      if (index == 3) {
        this.$router.push({
          path: "/newsInfo",
        });
      }
      if (index == 4) {
        this.$router.push({
          path: "/partner",
        });
      }
      if (index == 5) {
        this.$router.push({
          path: "/me",
        });
      }
    },
  },
};
</script>
<style scoped>
p {
  margin: 0;
  padding: 0;
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.wrap {
  /* height: 100vh; */
  width: 1920px;
  margin: 0 auto;
}
.cur {
  color: #028ccd !important;
}
.nav .navList {
  position: absolute;
  top: 25px;
  right: 300px;
  height: 50px;
}
/* 导航 */
.nav {
  width: 100%;
  height: 100px;
  background: #fff url("../../public/imgs/indexNew/logo.png") no-repeat 76px
    center;
  overflow: hidden;
  cursor: pointer;
  position: fixed;
  top: 0;
  z-index: 8888;
}
.navList span {
  display: block;
}
.navList div {
  text-align: center;
  float: left;
  line-height: 50px;
  height: 70px;
  width: 70px;
  margin-right: 45px;
  font-size: 16px;
  color: #666666;
  position: relative;
  top: 0;
  transition: top 0.5s;
}
.navList div span:nth-of-type(1) {
  opacity: 1;
  transition: all 0.5s;
}
.navList div span:nth-of-type(2) {
  opacity: 0;
  transition: all 0.5s;
}
.navList div:hover {
  top: -50px;
}
.navList div:hover span:nth-of-type(1) {
  opacity: 0;
}
.navList div:hover span:nth-of-type(2) {
  opacity: 1;
}
.title {
  margin: 150px auto;
  width: 362px;
  height: 75px;
}

.main {
  width: 800px;
  margin: 0 auto;
}
.main img {
  margin-bottom: 60px;
  cursor: pointer;
}
.main img:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: transform 0.5s ease 0s;
}
.main img:nth-of-type(1) {
  margin-right: 104px;
}
.main img:nth-of-type(3) {
  margin-right: 104px;
}
</style>
